import * as React from "react";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Flex,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
	return (
		<Layout>
			<Flex w="100%" h="80vh" justify="center">
				<VStack align="center" justify="center" spacing="1.5rem">
					<Heading as="h1">404 Not Found</Heading>
					<Text>This content cannot be found, or does not exist.</Text>
					<Box
						bg="blue.600"
						color="white"
						p="1rem"
						borderRadius="lg"
						as={Link}
						to="/"
					>
						Retun home
					</Box>
				</VStack>
			</Flex>
		</Layout>
	);
};

export default NotFoundPage;
